<template>
  <page
    v-bind="{ loading }"
    :title="loading ? 'Loading…' : `Support Ticket #${ticket.reference}`"
    icon="life-ring"
    loading-text="Loading ticket…">
    <div class="is-flex align-center" slot="tools">
      <span class="tag mr is-rounded is-medium"
        :class="ticketPriorityClass">
        <span class="mr">Priority:</span>
        <span class="has-text-weight-bold">{{ ticket.display_priority }}</span>
      </span>
      <span class="tag mr is-rounded is-medium"
        :class="ticketTypeClass">
        <span class="mr">Type:</span>
        <span class="has-text-weight-bold">{{ ticket.display_type }}</span>
      </span>
      <span class="tag mr is-rounded is-medium has-text-weight-bold"
        :class="ticketStatusClass">
        {{ ticket.current_status.value }}
      </span>
      <dropdown-wrapper position="bottom-end" v-if="!ticket.closed_at && !ticket.resolved_at">
        <action-button
          v-bind="{ working }"
          slot="trigger"
          slot-scope="{ isActive }"
          class="is-white is-rounded"
          left-icon="cog"
          :class="{ 'is-info is-inverted': isActive }">
          Actions
        </action-button>

        <template v-if="ticketStatusCanBeChanged">
          <dropdown-item @click="markResolved">
            <div class="is-flex">
              <icon icon="check-circle" class="has-text-success mr is-size-5"/>
              <div>
                <span class="is-block has-text-weight-bold">Resolve Ticket</span>
                <span class="has-text-grey">Mark the ticket as resolved, and then close it.</span>
              </div>
            </div>
          </dropdown-item>
          <dropdown-item @click="close">
            <div class="is-flex">
              <icon icon="times-circle" class="has-text-danger mr is-size-5"/>
              <div>
                <span class="is-block has-text-weight-bold">Close Ticket</span>
                <span class="has-text-grey">Close the ticket (no resolution required).</span>
              </div>
            </div>
          </dropdown-item>
        </template>
        <dropdown-item v-else class="pointer-events-none">
          <div>
            <p class="has-text-black-ter has-text-weight-bold">Ticket Status Locked</p>
            <p>One or more actionables in this ticket <br> are awaiting finalisation.</p>
          </div>
        </dropdown-item>
      </dropdown-wrapper>
    </div>

    <div class="box is-flex justify-between align-center">
      <div class="is-flex align-center">
        <avatar
          class="mr-1"
          :size="38"
          :path="ticket.user.profile ? ticket.user.profile.picture_url : null"
          :name="ticket.user.full_name"/>
        <div>
          <h1 class="is-size-5 has-text-weight-semibold has-text-black-ter">{{ ticket.title }}</h1>
          <p class="has-text-weight-light">Opened <abbr :title="ticket.created_at">{{ ticket.created_at | date('L') }}</abbr> by {{ ticket.user.full_name }}</p>
        </div>
      </div>
      <div class="is-flex align-center" v-if="ticket.participants.length">
        <p class="has-text-cool-blue-text">{{ ticket.participants.length }} {{ 'participant' | plural(ticket.participants.length) }}:</p>
        <div
          class="ml-1 has-tooltip-bottom has-tooltip-white has-avatar"
          :data-tooltip="participant.full_name"
          v-for="participant in ticket.participants"
          :key="participant.id">
          <avatar
            :size="38"
            :path="participant.profile.picture_url"
            :name="participant.full_name"/>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-third">
        <div class="box p-2">
          <p class="has-text-weight-bold is-uppercase tracking-wide has-text-cool-blue-text mb-1">{{ ticketBodyTitle }}</p>
          <ticket-body v-bind="{ ticket }"/>
        </div>
        <div class="box p-2">
          <p class="has-text-weight-bold is-uppercase tracking-wide has-text-cool-blue-text mb">Attachments</p>
          <div class="grid gap-1" v-if="ticket.files.length">
            <div v-for="file in ticket.files" :key="file.id">
              <a target="_blank" class="has-text-primary" :href="file.url">{{ file.title }}</a>
              <div class="is-flex is-size-7 has-text-black-ter has-text-weight-light">
                <span class="mr">
                  <template v-if="file.user_id">
                    uploaded by <span class="has-text-weight-normal">{{ file.user.full_name }}</span>
                  </template>
                  {{ file.created_at | date('[on] L') }}
                </span>•
                <span class="ml">{{ file.size | filesize }}</span>
              </div>
            </div>
          </div>
          <p v-else class="mt-1 has-text-cool-blue-text">This ticket has no attachments.</p>
          <div class="buttons mt-1">
            <action-button
              @click="attachFile"
              class="is-primary is-rounded is-outlined"
              left-icon="paperclip">
              Attach File
            </action-button>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box is-clipped is-paddingless">
          <p class="pt-2 plr-2 pb-0 has-text-weight-bold is-uppercase tracking-wide has-text-cool-blue-text mb-1">Comments</p>
          <section class="ticket-comments" v-if="ticket.comments.length">
            <ticket-comment v-for="comment in ticket.comments" :key="comment.id" v-bind="{ comment }"/>
          </section>
          <p v-else class="has-text-grey mtb-2 has-text-centered">No comments yet.</p>
          <new-ticket-comment class="new-ticket-comment" @added="loadTicket" />
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { newSupportTicketAttachment } from '@/modals'
import { mapState, mapMutations } from 'vuex'

import Comment from './partials/Comment'
import NewComment from './partials/NewComment'
import Body from './partials/Body'

export default {

  components: {
    'ticket-comment': Comment,
    'new-ticket-comment': NewComment,
    'ticket-body': Body,
  },

  data: () => ({
    loading: true,
    working: false,
  }),

  computed: {
    ...mapState('support', ['ticket']),

    ticketStatusClass() {
      return {
        'New': 'is-success',
        'Resolved': 'is-success',
        'Closed': 'is-danger',
        'Support Responded': 'is-info',
        'Customer Replied': 'is-link',
        'Under Consideration': 'is-cool-blue has-text-link',
        'Roadmapped': 'is-cool-blue has-text-link',
        'Under Review': 'is-cool-blue has-text-info',
      }[this.ticket.current_status.value]
    },

    ticketPriorityClass() {
      return {
        1: 'is-cool-blue-dark', // low
        2: 'is-cool-blue-dark has-text-info', // medium
        3: 'is-cool-blue-dark has-text-danger', // high
      }[this.ticket.priority]
    },

    ticketTypeClass() {
      return {
        1: 'is-cool-blue-dark has-text-success', // feature request
        2: 'is-cool-blue-dark has-text-link', // support request
        3: 'is-cool-blue-dark has-text-danger', // other
      }[this.ticket.type]
    },

    ticketStatusCanBeChanged() {
      let { value } = this.ticket.current_status
      return !['Roadmapped', 'Under Consideration', 'Under Review'].includes(value)
    },

    ticketBodyTitle() {
      return {
        1: 'Feature Request Details',
        2: 'Issue Details',
        3: 'Details',
      }[this.ticket.type]
    },

    ticketBody() {
      let { nl2br, linkify } = this.$options.filters
      return linkify(nl2br(this.ticket.body))
    },
  },

  mounted() {
    this.loadTicket()
  },

  beforeDestroy() {
    this.clearTicket()
  },

  methods: {
    ...mapMutations('support', ['clearTicket']),
    loadTicket() {
      this.$store
        .dispatch('support/loadTicket', this.$route.params.ticket)
        .then(() => this.loading = false)
    },
    markResolved() {
      this.working = true
      this.$store
        .dispatch('support/resolveTicket')
        .then(this.loadTicket)
        .catch(this.$whoops)
        .finally(() => this.working = false)
    },
    async attachFile() {
      if (await newSupportTicketAttachment()) {
        this.$toast.success('File attached.')
      }
    },
    close() {
      this.working = true
      this.$store
        .dispatch('support/closeTicket')
        .then(this.loadTicket)
        .catch(this.$whoops)
        .finally(() => this.working = false)
    }
  }

}
</script>
