<template>
  <div>
    {{ ticket.body }}
  </div>
</template>

<script>
export default {

  props: {
    ticket: {
      type: Object,
      required: true,
    }
  },

  computed: {
    compiledBody() {
      let { nl2br, linkify } = this.$options.filters
      return linkify(nl2br(this.ticket.body))
    },

    bodyComponent() {
      return {
        template: `<div>${this.compiledBody}</div>`,
      }
    }
  }

}
</script>
