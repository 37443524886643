<template>
  <article class="comment" :id="`comment-${comment.id}`">
    <avatar class="flex-shrink-none mr-1" :path="comment.user.profile ? comment.user.profile.picture_url : null" :name="comment.user.full_name"/>
    <div class="comment-content flex-grow">
      <p class="mb has-text-grey">
        <span class="has-text-weight-bold has-text-black">{{ comment.user.full_name }}</span>
        replied <moment :time="comment.created_at"/>
      </p>
      {{ comment.text }}
    </div>
  </article>
</template>

<script>
export default {

  props: {
    comment: {
      type: Object,
      required: true,
    }
  },

}
</script>
