<template>
  <page
    v-bind="{ loading }"
    :title="`${$route.path.includes('company') ? 'Company' : 'Your'} Support Tickets`"
    icon="life-ring"
    loading-text="Loading tickets…">

    <div class="buttons" slot="tools">
      <action-button @click="openSupportTicket" class="is-primary is-rounded" left-icon="plus">New Ticket</action-button>
    </div>

    <div class="box">
      <form class="grid gap-1 has-6-columns">
        <text-input
          class="is-marginless is-3-columns"
          v-model="filters.search"
          @input="runFilters"
          :debounce="500"
          left-icon="search"
          placeholder="Search by title, user, or reference"
          :label="false">
          <action-button
            v-if="filters.search"
            slot="right"
            class="is-light"
            @click="clearFilter('search')">
            <icon icon="times"/>
          </action-button>
        </text-input>

        <data-selector
          class="is-marginless"
          v-model="filters.type"
          @input="runFilters"
          :items="ticketTypes"
          prompt-label="Filter by type"
          :label="false">
          <action-button
            v-if="filters.type"
            slot="right"
            class="is-light"
            @click="clearFilter('type')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <data-selector
          class="is-marginless"
          v-model="filters.priority"
          @input="runFilters"
          :items="ticketPriorities"
          prompt-label="Filter by priority"
          :label="false">
          <action-button
            v-if="filters.priority"
            slot="right"
            class="is-light"
            @click="clearFilter('priority')">
            <icon icon="times"/>
          </action-button>
        </data-selector>

        <data-selector
          class="is-marginless"
          v-model="filters.status"
          @input="runFilters"
          :items="ticketStatuses"
          prompt-label="Filter by status"
          :label="false">
          <action-button
            v-if="filters.status"
            slot="right"
            class="is-light"
            @click="clearFilter('status')">
            <icon icon="times"/>
          </action-button>
        </data-selector>
      </form>
    </div>

    <div class="box">
      <table class="table is-hoverable is-vcentered">
        <thead>
          <tr>
            <th>Title</th>
            <th>User</th>
            <th>Ref.</th>
            <th>Status</th>
            <th class="is-narrow">Priority &amp; Type</th>
            <th class="is-narrow has-text-centered">Comments</th>
            <th class="is-narrow has-text-centered">Files</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ticket in tickets.data" :key="ticket.id" @click="$router.push({
            name: 'support.ticket',
            params: { ticket: ticket.reference }
          })" class="cursor-pointer">
            <td>
              <p class="has-text-weight-bold has-text-black-ter">{{ ticket.title }}</p>
              <p class="has-text-weight-light">{{ ticket.body | truncate(60) }}</p>
            </td>
            <td>
              <div>
                <p>{{ ticket.user.full_name }}</p>
                <p class="is-size-7" v-if="ticket.created_at !== ticket.updated_at">
                  updated {{ ticket.updated_at | date('L') }}
                </p>
                <p class="is-size-7" v-else>
                  opened {{ ticket.created_at | date('L') }}
                </p>
              </div>
            </td>
            <td>{{ ticket.reference }}</td>
            <td>
              <span class="button is-small is-rounded" :class="statusClass(ticket.current_status.value)">
                {{ ticket.current_status.value }}
              </span>
            </td>
            <td>
              <span class="is-block">{{ ticket.display_priority }}</span>
              <span class="is-block is-size-7 has-text-weight-bold has-text-info" v-if="ticket.type">{{ ticket.display_type }}</span>
            </td>
            <td class="has-text-centered">{{ ticket.comments_count || '-' }}</td>
            <td class="has-text-centered">{{ ticket.files_count || '-' }}</td>
          </tr>
        </tbody>
      </table>

      <pager :pageable="tickets" context="ticket" @nav="goToPage"/>
    </div>
  </page>
</template>

<script>
import { mapState } from 'vuex'
import { newSupportTicket } from '@/modals'
import { filtering } from '@/mixins'

export default {

  mixins: [
    filtering
  ],

  data: () => ({
    loading: true,
    ticketPriorities: [
      { value: 1, label: 'Low' },
      { value: 2, label: 'Medium' },
      { value: 3, label: 'High' },
    ],
    ticketTypes: [
      { value: 1, label: 'Feature Request' },
      { value: 2, label: 'Support Request' },
      { value: 3, label: 'Other' },
    ],
    ticketStatuses: [
      'New',
      'Resolved',
      'Closed',
      'Support Responded',
      'Customer Replied',
      'Under Consideration',
      'Roadmapped',
      'Under Review',
    ].map((status) => ({ label: status, value: status })),
    filters: {
      type: null,
      priority: null,
      status: null,
      search: null,
    },
  }),

  mounted() {
    this.loadTickets()
  },

  watch: {
    $route: 'loadTickets'
  },

  computed: {
    ...mapState('support', [
      'tickets'
    ])
  },

  methods: {
    loadTickets() {
      this.$store.dispatch('support/loadTickets', { path: 'api' + this.$route.fullPath })
        .catch(this.$whoops)
        .finally(() => this.loading = false)
    },

    goToPage(path) {
      this.$router.push(path)
    },

    statusClass(status) {
      switch (status) {
        case 'Closed': return 'is-success'
        case 'Customer Replied': return 'is-warning'
        case 'New': return 'is-primary'
        case 'Resolved': return 'is-success'
        case 'Support Responded': return 'is-info'
        case 'Under Consideration': return 'is-cool-blue has-text-link'
        case 'Roadmapped': return 'is-cool-blue has-text-link'
        case 'Under Review': return 'is-cool-blue has-text-info'
      }
    },

    async openSupportTicket() {
      let ticket = await newSupportTicket()
      if (ticket) {
        this.$toast.success(this.$lang.support.ticketOpened)
        this.$router.push({
          name: 'support.ticket',
          params: { ticket: ticket.reference }
        })
      }
    }
  }

}
</script>
