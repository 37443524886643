<template>
  <article class="new-comment">
    <text-area autogrow
      :label="false"
      :error="$root.errors.text"
      :value="comment.text"
      @input="setCommentText"/>
    <div class="is-flex justify-end">
      <action-button
        @click="submit"
        :disabled="!comment.text"
        class="is-primary plr-2"
        v-bind="{ working }">
        Add Comment
      </action-button>
    </div>
  </article>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {

  data: () => ({
    body: '',
    working: false
  }),

  computed: {
    ...mapState('support', ['comment'])
  },

  methods: {
    ...mapMutations('support', ['setCommentText']),
    submit() {
      this.working = true
      this.$store.dispatch('support/addComment')
        .then(() => this.$emit('added'))
        .finally(() => this.working = false)
    }
  }

}
</script>
